/*! Generated by Fontspring (https://www.fontspring.com) on December 21, 2018 
* 
* Fully installable fonts can be purchased at http://www.fontspring.com
*
* The fonts included in this stylesheet are subject to the End User License you purchased
* from Fontspring. The fonts are protected under domestic and international trademark and 
* copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
* distributing this font software.
*
* (c) 2010-2018 Fontspring
*
*
*/

@font-face {
    font-family: 'tensoregular';
    src: url('../fonts/03_Tenso_Regular-webfont.woff2') format('woff2'),
         url('../fonts/03_Tenso_Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'tensomedium';
    src: url('../fonts/05_Tenso_Medium-webfont.woff2') format('woff2'),
         url('../fonts/05_Tenso_Medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'tensolight_italic';
    src: url('../fonts/02_Tenso_Light_Italic-webfont.woff2') format('woff2'),
         url('../fonts/02_Tenso_Light_Italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}





body {
    font-family: 'tensoregular' !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'tensomedium' !important;
}





.font-primary {
    font-family: 'tensoregular' !important;
}

.font-monospace {
    font-family: 'tensolight_italic' !important;
}

.font-secondary {
    font-family: 'tensomedium' !important;
}


.btn-link:hover,
.btn-link:active,
.btn-link:focus {
    text-decoration: none;
}